import React, { useState, useCallback } from "react";
import { graphql, useStaticQuery } from 'gatsby'
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const PhotoGallery = () => {
    const data = useStaticQuery(query);

    const photos = data.allFile.edges.map(item => (
        {
            src: item.node.childImageSharp.fluid.src,
            width: item.node.childImageSharp.original.width,
            height: item.node.childImageSharp.original.height
        })
    )

    const oldphotos = [
        {
            src: "galaxy-pano.jpg",
            width: 5,
            height: 3
        },
        {
            src: "https://lightroom.adobe.com/v2c/catalogs/edce6594d6fe466fbd3832608f2eedd0/assets/14260489105047829dc7befa7daef51b/revisions/279302e67a994aad9c2713ed4111bf21/renditions/e5ce1ae9504ce47d433d188b09cbfdbb",
            width: 5,
            height: 3
        },
        {
            src: "https://lightroom.adobe.com/v2c/catalogs/edce6594d6fe466fbd3832608f2eedd0/assets/522774a25a9d4e5d9487e33e1d37903d/revisions/404c9ec2d8f6432fa48a573c808ac115/renditions/2e164e27d310efb9849610384acb8a29",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
            width: 3,
            height: 4
        },
        {
            src: "https://source.unsplash.com/PpOHJezOalU/800x599",
            width: 4,
            height: 3
        },
        {
            src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
            width: 4,
            height: 3
        }
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'photoClick',
            'category': 'Photo Click',
            'action': 'Click',
            'label': photo.src.split('/').slice(-1).pop()
        });
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}

export default PhotoGallery

// allFile(filter: { relativeDirectory: { eq: "images" }, extension: { regex: "/(jpg)|(jpeg)|(png)/" }, sort: { order: DESC, fields: modifiedTime } }) {
const query = graphql`
  query indexQuery {
  allFile(filter: {relativePath: {regex: "/(jpg)/"}}, sort: {order: ASC, fields: modifiedTime}) {
    edges {
      node {
        childImageSharp {
          original {
            width
            height
          }
          fluid {
            ...GatsbyImageSharpFluid_withWebp
            originalName
            originalImg
          }
        }
      }
    }
  }
}

`
